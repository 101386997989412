<template>
	<section v-if="showStickySummary" class="c-sticky-summary" data-testid="basket-sticky-summary">
		<div class="c-sticky-summary__call-to-actions" :class="{ 'c-sticky-summary__call-to-actions--hidden-total' : hideAllPrices || hideTotalPrice }">
			<div v-if="!hideAllPrices && !hideTotalPrice">
				<DsText html-element="div" text-type="body-xs">
					<template v-if="e1TaxCalculate.isNeedE1TaxCalculate && e1TaxCalculate.taxParams?.isTaxIncluded">
						{{ labels.totalInclVat }}
					</template>
					<template v-else>
						{{ labels.totalLabel }}
					</template>
				</DsText>
				<DsText class="c-sticky-summary__total" html-element="span" text-type="body-lg" data-testid="formattedTotalPrice">
					<template v-if="e1TaxCalculate.isNeedE1TaxCalculate">
						<InlineSpinner v-if="e1TaxCalculate.isE1TaxLoading" />
						<template v-else>
							{{ e1TotalInclTax }}
						</template>
					</template>
					<template v-else>
						{{ basket.formattedTotal }}
					</template>
				</DsText>
			</div>
			<div class="c-sticky-summary__go-to-checkout-button-container">
				<DsButton
				    @click="goToCheckout"
					:disabled="basket.isEmpty"
					class="c-sticky-summary__go-to-checkout-button"
					:class="{ disableGoToCheckout: 'c-btn__disable-on-loading' }"
					variant="primary">{{ labels.checkout }}</DsButton>
			</div>
		</div>

		<div
			v-if="!hideAllPrices"
			class="c-sticky-summary__drawer-container"
			data-testid="drawerContainer"
			:class="isOpen ? 'open' : ''">
			<button
				class="c-sticky-summary__drawer-button"
				@click="toggleDrawer"
				data-testid="drawerToggle"
				:aria-label="labels.accessibility.toggleBasketSummary"
				aria-controls="basket-summary"
				:aria-expanded="isOpen ? true : false">
				<DsIcon icon-name="chevron-up" :class="isOpen ? 'open' : ''" />
			</button>

			<div class="c-sticky-summary__drawer" id="basket-summary">
				<BasketMediLinkPartialPaymentTotal
					v-if="isConsumerPrescriptionsEnabled"
					:labels="labels"
				/>
				<fly-out-basket-total
					v-else
					:labels="labels"
					:show-total="false"
				/>

				<notification
					v-if="isShowE1Message"
					iconName="information-circle"
					:message="labels.e1FixedTaxRateInformationalMessage"
					data-testid="e1FixedTaxRate"
				/>

				<template v-if="e1TaxCalculate.isNeedE1TaxCalculate">
					<notification
						v-if="e1TaxCalculate.taxParams && e1TaxCalculate.taxParams.isPostCodeUnknown !== null"
						:message="e1TaxCalculate.taxParams.isPostCodeUnknown ? labels.notAdjustedVatMessage : labels.adjustedVatMessage"
						:iconName="!e1TaxCalculate.taxParams.isPostCodeUnknown ? 'checkmark' : 'information-circle'"
						data-testid="vatMessage" />
				</template>
				<template v-else>
					<notification
						:message="labels.vatMessage"
						:iconName="!basket.isTaxValidated ? 'checkmark' : 'information-circle'"
						v-if="basket.marketSpecificTax"
						data-testid="vatMessage" />
				</template>

				<notification
					:message="labels.payLaterNotificationText"
					v-if="showPayFastBlock"
					data-testid="payFastMessage" />
			</div>
		</div>
	</section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { track_begin_checkout } from 'shared/services/tracking/basketTrackingService';
import notification from 'shared/components/basket/notification.vue';
import InlineSpinner from 'shared/components/inlineSpinner.vue';
import FlyOutBasketTotal from 'shared/components/basket/flyOutBasketTotal.vue';
import BasketMediLinkPartialPaymentTotal from './basketMediLinkPartialPaymentTotal.vue';
import { DsButton, DsIcon, DsText } from '@coloplast/design-system';

export default {
	name: 'sticky-summary',
	components: {
		notification,
		InlineSpinner,
		FlyOutBasketTotal,
		BasketMediLinkPartialPaymentTotal,
		DsButton,
		DsIcon,
		DsText,
	},
	props: {
		labels: {
			type: Object,
			required: true,
		},
		hideTotal: {
			type: Boolean,
			default: false,
		},
		checkoutUrl: {
			type: String,
			required: true,
		},
		hideAllPrices: {
			type: Boolean,
			default: false,
		},
		isConsumerPrescriptionsEnabled: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			isOpen: false,
			disableGoToCheckout: false,
		};
	},
	computed: {
		...mapGetters({
			basket: 'getBasket',
			basketItemsCount: 'getBasketItemCount',
			e1TaxCalculate: "getE1TaxCalculate",
		}),
		showPayFastBlock() {
			return (
				this.labels.payLaterNotificationText && this.basket?.paid?.length > 0
			);
		},
		hideTotalPrice() {
			if (this.isConsumerPrescriptionsEnabled) {
				return true;
			}
			return this.basket.isReimbursableOnly && this.hideTotal;
		},
		showStickySummary() {
			return this.basket && this.basketItemsCount > 0;
		},
		e1TotalInclTax() {
			return this.e1TaxCalculate.taxParams?.price || this.basket.formattedTotal;
		},
		isShowE1Message() {
			return Boolean(
				this.e1TaxCalculate.taxParams?.showE1FixedTaxRateNotification
				&& !this.hasOnlySamplesLineItems
				&& this.numberOfItems > 0
			);
		},
		hasOnlySamplesLineItems() {
			return this.basket && this.basket?.samples?.length > 0 && this.basket?.paid?.length == 0;
		},
		numberOfItems () {
			const allItems = this.basket.paid || [];
			const paid = allItems.filter((item) => !item.isComplimentary);
			return paid.length;
		}

	},
	methods: {
		...mapActions([
			'showSpinner',
		]),
		goToCheckout() {
			this.showSpinner();
			if (this.disableGoToCheckout) return;
			track_begin_checkout(this.basket);
			this.disableGoToCheckout = true;
			window.location.assign(this.checkoutUrl);
		},
		toggleDrawer() {
			this.isOpen = !this.isOpen;
		},
	},
};
</script>

<style>
.c-sticky-summary__drawer-button:focus-visible {
	outline: 4px solid var(--ds-color-focus-default);
	border-radius: 4px;
}
</style>
