<script setup lang="ts">
import { GlobalSettings } from 'shared/types/globalSettings';
import { useStore } from 'vuex';

interface Props {
	globalSettings: {
		BaseViewModel: GlobalSettings;
	}
}

const props = defineProps<Props>();

const store = useStore();
store.commit('setGlobalSettings', props.globalSettings.BaseViewModel);
</script>

<template>
	<div></div>
</template>
